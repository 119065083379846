import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import Page from "./Page";

export default function Questions() {
 const { uid, id } = useParams();
 const [currentQuestion, setCurrentQuestion] = useState(null);
 const [userCurrentAnswer, setUserCurrentAnswer] = useState(-1);
 const [currentQuestionSubmitted, setCurrentQuestionSubmitted] =
  useState(false);

 const { status, data } = useGetInteractionQuestions(uid, id);

 const firestore = useFirestore();

 //  const headerRef = useRef();

 const handleGetCurrentQuestion = async () => {
  setUserCurrentAnswer(
   localStorage.getItem(`${uid}_${id}_${data.currentQuestion}`) || -1
  );

  await firestore
   .doc(`users/${uid}/interactions/${id}/questions/${data.currentQuestion}`)
   .get()
   .then((res) => {
    let d = res.data();
    setCurrentQuestion(d);

    console.log(d);
    if (!d?.allowMultiple) {
     setCurrentQuestionSubmitted(
      localStorage.getItem(`${uid}_${id}_${data.currentQuestion}_answered`) ||
       false
     );
    }
   });

  window.scrollTo(0, 0);
  //   headerRef.current.scrollIntoView();
 };

 const handleAnswerQuestion = (answer) => {
  localStorage.setItem(`${uid}_${id}_${data.currentQuestion}`, answer);
  setUserCurrentAnswer(
   localStorage.getItem(`${uid}_${id}_${data.currentQuestion}`) || -1
  );
 };

 const handleOnSubmit = async () => {
  let update = {};

  if (currentQuestion.type === "poll" || currentQuestion.type === "emoji") {
   update = {
    results: {
     [userCurrentAnswer]: firestore.FieldValue.increment(1),
    },
    totalCount: firestore.FieldValue.increment(1),
   };
  } else if (currentQuestion.type === "openQandA") {
   update = {
    results: firestore.FieldValue.arrayUnion({
     text: userCurrentAnswer,
     name: localStorage.getItem(`name`) || `Anonymous`,
    }),
    totalCount: firestore.FieldValue.increment(1),
   };
  }
  await firestore
   .doc(`users/${uid}/interactions/${id}/questions/${data.currentQuestion}`)
   .set(update, { merge: true })
   .then(() => {
    localStorage.setItem(`${uid}_${id}_${data.currentQuestion}_answered`, true);
    setCurrentQuestionSubmitted(true);

    if (currentQuestion.allowMultiple) {
     return setTimeout(() => {
      setCurrentQuestionSubmitted(false);
      localStorage.setItem(
       `${uid}_${id}_${data.currentQuestion}_answered`,
       false
      );
      localStorage.setItem(`${uid}_${id}_${data.currentQuestion}`, "");
      handleGetCurrentQuestion();
     }, [3000]);
    }
   });
 };

 useEffect(() => {
  handleGetCurrentQuestion();
 }, [data.currentQuestion]);

 return (
  <Page heading={data.name}>
   <section className=" flex-1">
    {status !== "DONE"
     ? "Loading..."
     : !currentQuestion && (
        <span className=" text-gray-500">
         {data?.settings?.waiting_on_host || 'Waiting on the host to ask a question.'}
        </span>
       )}
    {currentQuestion?.type === "poll" && (
     <PollContents
      disabled={currentQuestionSubmitted}
      poll={currentQuestion}
      onAnswer={(index) => handleAnswerQuestion(index)}
      answer={userCurrentAnswer}
     />
    )}
    {currentQuestion?.type === "emoji" && (
     <EmojiContents
      disabled={currentQuestionSubmitted}
      poll={currentQuestion}
      onAnswer={(index) => handleAnswerQuestion(index)}
      answer={userCurrentAnswer}
     />
    )}
    {currentQuestion?.type === "openQandA" && (
     <OpenQandAContents
      disabled={currentQuestionSubmitted}
      poll={currentQuestion}
      onAnswer={(text) => handleAnswerQuestion(text)}
      answer={userCurrentAnswer}
     />
    )}
   </section>
   {currentQuestionSubmitted && (
    <span className="text-gray-400 text-right">{data?.settings?.submitted || "Submitted!"}</span>
   )}
   {currentQuestion && (
    <SubmitButton
     disabled={currentQuestionSubmitted}
     answer={userCurrentAnswer}
     onSubmit={handleOnSubmit}
     settings={data?.settings}
    />
   )}
  </Page>
 );
}

const PollContents = ({ disabled = false, poll, onAnswer, answer = -1 }) => {
 return (
  <div className="flex flex-col space-y-4 ">
   <div className="px-4 py-6 bg-gray-900 rounded text-xl">{poll.question}</div>
   <div className="flex flex-col space-y-2">
    {poll.options.map((option, index) => {
     return (
      <AnswerButton
       key={index}
       option={option}
       answer={answer}
       onAnswer={onAnswer}
       index={index}
       disabled={disabled}
      />
     );
    })}
   </div>
  </div>
 );
};

const EmojiContents = ({ disabled = false, poll, onAnswer, answer = -1 }) => {
 return (
  <div className="flex flex-col space-y-4 ">
   <div className="px-4 py-6 bg-gray-900 rounded text-xl">{poll.question}</div>
   <div className="flex w-full justify-evenly space-x-2">
    {poll.options.map((option, index) => {
     return (
      <AnswerButton
       key={index}
       option={option}
       answer={answer}
       onAnswer={onAnswer}
       index={index}
       disabled={disabled}
       css={`w-1/2 text-center text-4xl`}
      />
     );
    })}
   </div>
  </div>
 );
};

const OpenQandAContents = ({
 disabled = false,
 poll,
 onAnswer,
 answer = "",
}) => {
 return (
  <div className="flex flex-col space-y-4 ">
   <div className="px-4 py-6 bg-gray-900 rounded text-xl">{poll.question}</div>
   <div className="flex flex-col space-y-2">
    <AnswerInput answer={answer} onAnswer={onAnswer} disabled={disabled} />
   </div>
  </div>
 );
};

export const SubmitButton = ({
 disabled = false,
 answer = false,
 onSubmit,
 submitText = "Submit",
 settings,
}) => {
 return (
  <button
   className={`px-4 py-4 flex justify-between border-2 bg-gray-200 w-full rounded text-left font-extrabold focus:outline-none focus:ring focus:ring-gray-400  transition-all duration-150
   ${
    Boolean(disabled)
     ? "bg-gray-800 border-4 border-gray-900 text-gray-300 cursor-default"
     : "text-gray-900"
   }
   ${answer === -1 && "opacity-30"}
   `}
   disabled={Boolean(disabled) || answer === -1 ? true : false}
   onClick={onSubmit}
  >
   {Boolean(disabled)
    ? (settings?.waiting_for_your_answer || "Waiting for host")
    : answer !== -1
    ? <>
      {settings?.submit || "Submit"}
      {<svg
       xmlns="http://www.w3.org/2000/svg"
       className="h-6 w-6"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
       strokeWidth="2"
      >
       <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m13 9 3 3m0 0-3 3m3-3H8m13 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
       />
      </svg>}
    </>
    : (settings?.waiting_for_your_answer || "Waiting for your answer")}
  </button>
 );
};

const useGetInteractionQuestions = (uid, id) => {
 const [checking, setChecking] = useState(true);
 const [data, setData] = useState({});
 const [empty, setEmpty] = useState(false);

 const firestore = useFirestore();

 const fetchData = async () => {
  setChecking(true);
  firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions`,
    doc: id,
    storeAs: "interactions",
   },
   (res) => {
    if (!res.exists) {
     setChecking(false);
     return setEmpty(true);
    }

    setData(res.data());
    setChecking(false);
   }
  );
 };

 useEffect(() => {
  fetchData();

  return () => {
   firestore.unsetListener(`users/${uid}/interactions`);
  };
 }, []);

 if (checking) return { status: "CHECKING", data: data };

 if (empty) return { status: "EMPTY", data: data };

 return { status: "DONE", data: data };
};

const AnswerButton = ({ option, answer, onAnswer, index, disabled, css }) => {
 return (
  <button
   className={`px-4 py-4  rounded text-lg border-4  text-left focus:outline-none focus:ring focus:ring-gray-700  transition-all duration-150
       ${
        parseInt(answer) === index
         ? "bg-gray-900 border-gray-900"
         : "bg-gray-800 border-gray-900"
       }
       ${Boolean(disabled) && "cursor-default"}
       ${css}
      `}
   onClick={() => onAnswer(index)}
   disabled={Boolean(disabled)}
  >
   {option.text}
  </button>
 );
};

export const AnswerInput = ({
 answer,
 onAnswer,
 disabled,
 placeholder = "Type your answer here",
 autoFocus = false,
}) => {
 const [text, setText] = useState("");

 useEffect(() => {
  setText(answer === -1 ? "" : answer);

  return () => {
   console.log("UNloading");
   setText("");
  };
 }, [answer]);
 return (
  <input
   className={`px-4 py-4 w-full rounded text-lg border-4 bg-gray-800 border-gray-900 text-left focus:outline-none focus:ring focus:ring-gray-700  transition-all duration-150
      ${answer ? "bg-gray-900 border-gray-900 " : "bg-gray-800 border-gray-900"}
      
      `}
   value={text}
   placeholder={placeholder}
   onChange={(e) => {
    setText(e.target.value);
    onAnswer(e.target.value);
   }}
   //  onBlur={(e) => onAnswer(e.target.value)}
   disabled={Boolean(disabled)}
   autoFocus={autoFocus}
  />
 );
};
