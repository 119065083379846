import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
// import GoogleButton from "react-google-button"; // optional

// import Logo from "./branding/Logo";
import H2RButton from "../H2RComponents/H2RButton";
import H2RInput from "../H2RComponents/H2RInput";
import Logo from "../Logo";

function Login() {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [loggingIn, setLoggingIn] = useState(false);
 const [error, setError] = useState("");

 const firebase = useFirebase();
 const auth = useSelector((state) => state.firebase.auth);

 //  const loginWithGoogle = async () => {
 //   return await firebase.login({ provider: "google", type: "popup" });
 //  };

 const handleLogin = (e) => {
  e.preventDefault();

  setLoggingIn(true);

  if (!email || !password) return setLoggingIn(false);
  return firebase.login({ email, password }).catch((err) => {
   if (err.code === "auth/user-not-found") {
    setError("User not found");
   } else if (err.code === "auth/wrong-password") {
    setError("Incorrect Email or Password");
   } else {
    setError("Something went wrong. Try again.");
   }

   setLoggingIn(false);
  });
 };

 return (
  <div className="flex flex-col justify-center bg-gray-800 h-screen w-screen">
   {!isLoaded(auth) ? (
    <span>Loading...</span>
   ) : isLoaded(auth) && isEmpty(auth) ? (
    <div className="px-12 py-4 flex flex-col w-11/12 md:w-1/2 max-w-md bg-gray-900 border-2 border-gray-800 mx-auto rounded-lg">
     <form className="flex flex-col mb-5" onSubmit={(e) => handleLogin(e)}>
      <Logo />
      <span className="mt-8 mb-10 h-0.5  bg-gray-800"></span>
      <div className="mb-8 flex flex-col space-y-5">
       <H2RInput
        name="email"
        label="Email"
        type="email"
        value={email}
        onChange={(text) => setEmail(text)}
        placeholder="me@address.com"
        required={true}
        autoFocus={true}
       />
       <H2RInput
        name="password"
        label="Password"
        type="password"
        css="mt-6"
        value={password}
        onChange={(text) => setPassword(text)}
        placeholder="••••••••••"
        required={true}
       />
      </div>
      <div className="flex items-center justify-between">
       <H2RButton
        text={!loggingIn ? "Login" : "Logging in..."}
        colour="main"
        onSubmit={(e) => e.preventDefault()}
        disabled={loggingIn}
       />
       {/* <Link
								className="text-gray-700 px-2 focus:outline-none focus:ring rounded"
								to="/reset"
							>
								Forgot password?
							</Link> */}
      </div>
      {error !== "" ? (
       <span className="italic mt-4 mx-auto text-red-600">{error}</span>
      ) : null}
     </form>
     {/* <GoogleButton onClick={loginWithGoogle} /> */}
     <span className="mt-8 mb-10 h-0.5  bg-gray-800"></span>
     <span className="mb-4 flex justify-between text-gray-400">
      Don't have an account?
      <Link className="" to="/signup">
       <H2RButton
        text={"Sign up"}
        colour="dark"
        size="small"
        onClick={() => null}
       />
      </Link>
     </span>
    </div>
   ) : (
    <Redirect to="/"></Redirect>
   )}
  </div>
 );
}

export default Login;
