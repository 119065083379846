import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import Page from "./Page";
import { QRCodeSVG } from "qrcode.react";

export default function QRandInfo() {
 const { uid, id } = useParams();
 const [currentQuestion, setCurrentQuestion] = useState(null);

 const { status, data } = useGetInteractionQuestions(uid, id);

 const firestore = useFirestore();

 const handleGetCurrentQuestion = async () => {
  await firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions/${id}/questions`,
    doc: `${data.currentQuestion}`,
    storeAs: "interaction",
   },
   (res) => {
    if (!res.exists) {
     setCurrentQuestion(null);
     return null;
    }

    setCurrentQuestion(res.data());
   }
  );

  window.scrollTo(0, 0);
 };

 useEffect(() => {
  handleGetCurrentQuestion();
 }, [data.currentQuestion]);

 useEffect(() => {
  const style = document.createElement("style");

  style.className = "cookieHider";
  style.textContent = ".CookieConsent {display: none!important;}";
  document.head.append(style);
 }, []);

 return (
  <Page heading={data.name}>
   <section
    style={{
     fontSize: "5vh",
    }}
    className="flex-1"
   >
    {status !== "DONE" ? (
     "Loading..."
    ) : (
     <div
      style={{
       margin: "10vh",
      }}
      className="flex flex-col md:flex-row justify-around text-center"
     >
      <div className="flex flex-col items-center">
       <span
        style={{
         fontSize: "2vh",
         padding: "4vh",
        }}
        className="text-gray-400 uppercase font-semibold"
       >
        {data?.settings?.scan_to_join || 'Scan QR code to join'}
       </span>
       <QRCodeSVG
        value={`https://h2r.li/${id}`}
        style={{
         height: `12vw`,
         width: `12vw`,
         aspectRatio: `1/1`,
         padding: ".5vw",
         backgroundColor: "white",
        }}
       />
      </div>
      <span
       style={{
        marginTop: "4vh",
       }}
       className="border-r border-gray-600"
      ></span>
      <div className="flex flex-col">
       <span
        style={{
         fontSize: "2vh",
         padding: "3vh",
        }}
        className="text-gray-400 uppercase font-semibold"
       >
        {data?.settings?.visit_link || 'Visit the link to join'}
       </span>
       <span>h2r.li/{id}</span>
      </div>
     </div>
    )}
   </section>
  </Page>
 );
}

const useGetInteractionQuestions = (uid, id) => {
 const [checking, setChecking] = useState(true);
 const [data, setData] = useState({});
 const [empty, setEmpty] = useState(false);

 const firestore = useFirestore();

 const fetchData = async () => {
  setChecking(true);
  firestore.onSnapshot(
   {
    collection: `users/${uid}/interactions`,
    doc: id,
    storeAs: "interactions",
   },
   (res) => {
    if (!res.exists) {
     setChecking(false);
     return setEmpty(true);
    }

    setData(res.data());
    setChecking(false);
   }
  );
 };

 useEffect(() => {
  fetchData();

  return () => {
   firestore.unsetListener(`users/${uid}/interactions`);
  };
 }, []);

 if (checking) return { status: "CHECKING", data: data };

 if (empty) return { status: "EMPTY", data: data };

 return { status: "DONE", data: data };
};
